<template>
  <span v-if="!loading">{{ username }}</span>
  <span v-else
    ><Skeleton class="inline-block" width="100px" height="13.5px"
  /></span>
</template>

<script setup lang="ts">
const { userId } = defineProps<{
  userId: {
    type: string;
    required: true;
  };
}>();
const username = ref<string | null>(null);
const loading = ref(true);

const getUserName = async () => {
  loading.value = true;
  const user = await useFetch(`/api/cached/users/${userId}`, {
    method: "GET",
  });
  username.value = user.data.value.user?.name;
  loading.value = false;
};

onMounted(() => {
  getUserName();
});
</script>
